import React from "react"

import Layout from "../../components/litjen-layout"
import SEO from "../../components/litjen-seo"

const LitjenLuke = () => (
  <Layout>
    <SEO title="Luke 6" />
    <div>
      <h2>En Litjen adventskalender</h2>
      <h1>Luke 6</h1>
      <p>Helgen er ikke over!</p>
      <p>
        Svart blekk trengte du, mamma. Det holder jo ikke bare med fargeblekk.
      </p>
      <p>
        I dag er en spennende dag. Innimellom, så kommer slike dager. Det som i
        dag er på alles lepper nemlig, er om jeg, en Litjen, skal bli en Bitjen.
      </p>
      <p>
        Helt ærlig, så aner jeg ikke selv. Men vi får bare vente å se. Uansett så vet jeg at
        jeg er i de beste hender!
      </p>
      <p>Hilsen Litjen</p>
      <p>PS! Glem alt det over. Jeg har nå vært en Bitjen! JIPPI!!!</p>
    </div>
  </Layout>
)

export default LitjenLuke
